<template>
  <v-container class="mt-6">
    <v-row>
      <p v-if="!fetchingData" class="text-h6">
        Bis heute wurden insgesamt
        <strong>{{ memberCount }} neue Mitglieder</strong> über das mitfitMEMBER
        System generiert.
      </p>

      <v-col class="col-12">
        <SalesDevelopmentCard />
      </v-col>

      <v-col class="col-12 col-md-6 col-lg-6">
        <PaymentDistributionCard />
      </v-col>

      <v-col class="col-12 col-md-6 col-lg-6">
        <GenderDistributionCard />
      </v-col>
    </v-row>

    <DataTable
      :headers="headers"
      :items="leads"
      :preloader="fetchingData"
      :edit="true"
      :trash="false"
      class="mt-6"
      @tableEvent="emittedTableEvent"
    ></DataTable>

    <v-dialog
      v-model="dialog"
      max-width="1500px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="dialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-toolbar-title>Lead</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container style="max-width: 1500px; min-height: 90vh" fluid>
            <LeadDetailView
              ref="lead_detail"
              :leadId="leadId"
              :key="componentKey"
              type="member"
              @orderTerminated="orderTerminated()"
            ></LeadDetailView>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import DataTable from "@/components/ui/DataTable";
import SalesDevelopmentCard from "@/components/addons/member/SalesDevelopmentCard";
import GenderDistributionCard from "@/components/addons/member/GenderDistributionCard";
import PaymentDistributionCard from "@/components/addons/member/PaymentDistributionCard";
import LeadDetailView from "@/views/leads/LeadDetailView";
export default {
  name: "MemberLeads",

  components: {
    DataTable,
    LeadDetailView,
    SalesDevelopmentCard,
    GenderDistributionCard,
    PaymentDistributionCard,
  },

  data() {
    return {
      fetchingData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      data: [], // wrapper for fetched data
      leads: [], // wrapper for fetched data
      leadsStatsByGender: [],
      leadsStatsByYearMonth: [],
      leadsStatsByPaymentStatus: [],
      leadId: null,
      leadData: [],
      campaignData: [],
      headers: [],
      dialog: false,
      deleteDialog: false,
      componentKey: 0,
      itemData: [],
      itemId: null,
      itemIndex: null,
      memberCount: 0,
    };
  },

  created: function () {
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        this.getData();
      }
    );

    this.getData();
  },

  methods: {
    emittedTableEvent(obj) {
      this.leadId = obj.itemId;
      this.dialog = true;
      this.componentKey += 1;
    },

    orderTerminated() {
      this.dialog = false;
      this.getData();
    },

    async getData() {
      /* fetch data from api 
      companies/1/member-leads */
      this.fetchingData = true;
      let response = await this.getRequest(
        `companies/${this.companyID}/memberLeads`
      );
      this.fetchingData = false;
      this.data = response.data.data;
      this.leads = response.data.data;
      this.memberCount = response.data.data.length; // simple count of leads

      this.headers = [
        { text: "", value: "pay.isTerminated" },
        {
          text: this.$t("paymentMethod"),
          value: "pay.paymentStatus",
        },
        { text: this.$t("underageParticipant"), value: "underageParticipant" },
        { text: this.$t("date"), value: "created" },
        { text: this.$t("firstName"), value: "firstName" },
        { text: this.$t("lastName"), value: "lastName" },
        { text: this.$t("email"), value: "email" },
        { text: this.$t("contract"), value: "pay.itemTitle" },
        {
          text: "Download",
          value: "pay.document",
          sortable: false,
        },
        { text: "", value: "actions", sortable: false },
      ];
    },
  },
  mixins: [manageApi],
};
</script>
