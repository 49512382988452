<template>
  <v-container class="mt-6">
    <DataTable
      :headers="headers"
      :items="data"
      :preloader="fetchinData"
      :edit="isDisabledForNoneFaceforceStaff() ? false : true"
      :trash="isDisabledForNoneFaceforceStaff() ? false : true"
      @tableEvent="emittedTableEvent"
    ></DataTable>

    <v-container class="mt-6">
      <v-row>
        <v-col cols="12">
          <div class="d-flex align-end flex-column">
            <v-row>
              <v-btn
                large
                color="green"
                dark
                @click="addItem"
                :disabled="isDisabledForNoneFaceforceStaff() ? true : false"
              >
                {{ $t("addContract") }}
                <v-icon right dark>mdi-plus</v-icon>
              </v-btn>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="dialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="dialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-toolbar-title>{{ $t("contract") }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <ContractDetail
              :itemData="itemData"
              :new="false"
              :id="itemId"
              :key="componentKey"
              @closeDialog="dialog = false"
            ></ContractDetail>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogNewContract"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="dialogNewContract = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-toolbar-title>{{ $t("contract") }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <ContractDetail
              :itemData="null"
              :new="true"
              :key="componentKey"
              @closeDialog="dialogNewContract = false"
              @newItemAdded="newItemAdded"
            ></ContractDetail>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ConfirmationDialog
      :dialog="deleteDialog"
      :headline="$t('areYouSure')"
      :message="$t('deleteItemConfirmationText')"
      @reject="deleteDialog = false"
      @accept="deleteItem"
    />
  </v-container>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
import DataTable from "@/components/ui/DataTable";
import ContractDetail from "@/components/addons/member/ContractDetail";
import ConfirmationDialog from "@/components/ui/ConfirmationDialog";
export default {
  name: "ContractsList",

  components: {
    DataTable,
    ContractDetail,
    ConfirmationDialog,
  },

  data() {
    return {
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      data: [], // wrapper for fetched data
      headers: [],
      dialog: false,
      dialogNewContract: false,
      deleteDialog: false,
      componentKey: 0,
      itemData: [],
      itemId: null,
      itemIndex: null,
    };
  },

  created: function () {
    /* starts immediatly when component was created */

    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        this.getData();
      }
    );

    this.getData();
  },

  methods: {
    newItemAdded() {
      this.dialogNewContract = false;
      this.getData();
    },

    addItem() {
      this.componentKey += 1;
      this.dialogNewContract = true;
    },

    async deleteItem() {
      this.fetchinData = true;
      let response = await this.deleteRequest("pay/contracts/" + this.itemId);
      this.fetchinData = false;

      if (response.status === 200) {
        this.data.splice(this.itemIndex, 1);
        /* CALL SNACKBAR */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t("messageDeleted");
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
        this.deleteDialog = false;
      }
    },

    emittedTableEvent(value) {
      if (value.action === "editItem") {
        this.itemId = value.itemId;

        /* finde the real data in array
        vorher bin ich über INDEX gegangen, was sich aber als falsch herausgestellt hatte, da der INDEX auf die aktuelle Page der Tabelle bezogen war. */
        this.data.forEach((item) => {
          if (item.id === this.itemId) {
            this.itemData = item; // the data of that item
          }
        });

        this.dialog = true;
        this.componentKey += 1; // forces the component (here LeadDetail) to re-render -> https://michaelnthiessen.com/force-re-render/
      } else if (value.action === "deleteItem") {
        this.itemId = value.itemId;
        this.itemIndex = value.itemIndex;
        this.deleteDialog = true;
      }
    },

    async getData() {
      this.fetchinData = true;
      let response = await this.getRequest(
        `companies/${this.companyID}/contracts`
      );
      this.fetchinData = false;
      this.data = response.data.data.reverse();

      this.headers = [
        { text: "ID", value: "id" },
        {
          text: this.$t("title"),
          value: "title",
          cellClass: "font-weight-bold",
        },
        { text: this.$t("grossPrice"), value: "priceGross" },
        { text: this.$t("promotion"), value: "contractPromotionText" },
        { text: this.$t("billingPeriod"), value: "billingPeriod" },
        { text: this.$t("active"), value: "isActive" },
        { text: "", value: "actions", sortable: false },
      ];
    },
  },
  mixins: [manageApi, helpers],
};
</script>
