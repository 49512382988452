<template>
  <div>
    <v-card :loading="fetchingData">
      <v-toolbar dark color="dark">
        <v-toolbar-title>{{ $t("membershipGrowth") }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <Bar
          :chartData="chartData"
          :chartLabels="chartLabels"
          :backgroundColor="backgroundColor"
          :chartLabel="chartLabel"
          v-if="!fetchingData && dataProcessed"
          :height="100"
        /><!-- wenn daten für chart aufbereitet wurden -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import store from "@/store";
import Bar from "@/components/charts/Bar";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
export default {
  name: "SalesDevelopmentCard",

  components: {
    Bar,
  },

  data() {
    return {
      fetchingData: false,
      dataProcessed: false,
      chartData: [],
      chartLabels: [],
      backgroundColor: null,
      chartLabel: this.$t("membershipGrowth"),
      companyID: store.getters["auth/current_sid"],
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      // /companies/{companyID}/memberLeads/leadsStatsByYearMonth
      this.fetchingData = true;
      let response = await this.getRequest(
        `companies/${this.companyID}/memberLeads/leadsStatsByYearMonth`
      );
      this.fetchingData = false;
      if (response.status === 200) {
        this.data = response.data.data;
        this.processDataForChart();
      }
    },

    processDataForChart() {
      /* Aufbereiten der Daten für die Verwendung im Chart */
      //const dataArr = Object.values(this.data);
      Object.keys(this.data).forEach((key) => {
        this.chartLabels.push(key + " (+" + this.data[key] + ")");
        this.chartData.push(this.data[key]);
      });
      this.backgroundColor = "rgba(124,174,43,.5)";
      this.dataProcessed = true;
    },
  },
  mixins: [manageApi],
};
</script>
