<template>
  <v-container>
    <!-- <pre>{{ fields }}</pre> -->
    <v-form ref="form" v-model="isFormValid" v-on:submit.prevent="safeData()">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>{{ $t("Contract") }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('title')"
                    v-model="fields.title"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <p class="red--text">
                    <v-icon color="red"> mdi-alert-circle </v-icon>
                    Bei Verwenung eines externen Anbieters, muss der
                    Vertragstitel mit dem des externen Anbieter übereinstimmen!
                  </p>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :label="$t('grossPrice')"
                    type="number"
                    v-model="fields.priceGross"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :label="$t('vatRate')"
                    type="number"
                    v-model="fields.vatRate"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="fields.billingPeriod"
                    :items="billingPeriods"
                    item-text="name"
                    item-value="value"
                    :label="$t('billingPeriod')"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <h2>{{ $t("runtime") }}</h2>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :label="$t('periodOfTime')"
                    v-model="fields.runtimeCount"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="fields.runtimePeriod"
                    :items="runtimePeriods"
                    item-text="name"
                    item-value="value"
                    :label="$t('factor')"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card>
            <v-card-title>{{ $t("Details") }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    :label="$t('description')"
                    v-model="fields.description"
                    :rules="[rules.required]"
                  ></v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    :label="$t('features')"
                    v-model="fields.features"
                    :hint="$t('onePerRow')"
                  ></v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    :label="$t('finePrint')"
                    v-model="fields.finePrint"
                  ></v-textarea>
                </v-col>

                <v-col cols="12">
                  <h2>{{ $t("image") }}</h2>
                </v-col>

                <template v-if="itemData && itemData.mediaLink">
                  <v-col cols="12" sm="4" md="4" v-if="itemData.mediaLink">
                    <v-img
                      max-height="150"
                      max-width="250"
                      :src="itemData.mediaLink + '?version=' + randomValue"
                    ></v-img>
                  </v-col>

                  <v-col cols="12" sm="8" md="8">
                    <v-file-input
                      accept="image/*"
                      :label="$t('file')"
                      v-model="image"
                    ></v-file-input>
                  </v-col>
                </template>

                <template v-else>
                  <v-col cols="12">
                    <v-file-input
                      accept="image/*"
                      :label="$t('file')"
                      v-model="image"
                    ></v-file-input>
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card>
            <v-card-title>{{ $t("additionalCosts") }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :label="$t('title')"
                    v-model="fields.feeTitle"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :label="$t('grossPrice')"
                    v-model="fields.feePriceGross"
                    type="number"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    :label="$t('description')"
                    v-model="fields.feeDescription"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card color="light-green lighten-5">
            <v-card-title>{{ $t("Promotion") }}</v-card-title>

            <v-card-text>
              <div v-html="$t('promotionInfo')"></div>
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    :label="$t('promoTitle')"
                    type="text"
                    v-model="fields.promoTitle"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    :label="$t('promoPrice')"
                    type="number"
                    v-model="fields.promoPriceGross"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="4" md="4">
                  <v-select
                    v-model="fields.promoBillingPeriod"
                    :items="runtimePeriods"
                    item-text="name"
                    item-value="value"
                    :label="$t('factor')"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :label="$t('periodOfTime')"
                    v-model="fields.promoRuntimeCount"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="fields.promoRuntimePeriod"
                    :items="runtimePeriods"
                    item-text="name"
                    item-value="value"
                    :label="$t('factor')"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card color="blue lighten-5">
            <v-card-title>{{ $t("externalProvider") }}</v-card-title>
            <v-card-text>
              <div v-html="$t('externalProviderInfo')"></div>
              <v-row>
                <!-- apiProvidersUsed -->
                <v-col cols="12">
                  <v-switch
                    inset
                    v-model="fields.apiProvidersUsed"
                    flat
                    true-value="1"
                    false-value="0"
                    :label="$t('UseAnExternProvider')"
                  ></v-switch>
                </v-col>

                <template v-if="fields.apiProvidersUsed === '1'">
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      :label="$t('apiProviders_id')"
                      v-model="fields.apiProviders_id"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      :label="$t('apiProvidersContractId')"
                      v-model="fields.apiProvidersContractId"
                    ></v-text-field>
                  </v-col>

                  <!-- startAfterPromo -->
                  <v-col cols="12">
                    <v-switch
                      inset
                      v-model="fields.startAfterPromo"
                      flat
                      true-value="1"
                      false-value="0"
                      :label="$t('startAfterPromo') + ' (nur Magicline)'"
                    ></v-switch>
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card>
            <v-card-title>{{ $t("Payments") }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-switch
                    inset
                    v-model="fields.noPayment"
                    flat
                    true-value="1"
                    false-value="0"
                    :label="$t('noPayment')"
                  ></v-switch>

                  <template v-if="fields.noPayment === '1'">
                    <h3>{{ $t("noPaymentText") }}</h3>

                    <v-md-editor
                      v-model="fields.noPaymentText"
                      height="400px"
                      left-toolbar="h bold italic | ul ol | emoji"
                      right-toolbar="preview fullscreen"
                      :disabled-menus="['h/h1']"
                      mode="edit"
                    ></v-md-editor>
                  </template>

                  <template v-if="fields.noPayment !== '1'">
                    <v-switch
                      inset
                      v-model="fields.allowSepa"
                      flat
                      true-value="1"
                      false-value="0"
                      :label="$t('allowSepa')"
                    ></v-switch>

                    <v-switch
                      inset
                      v-model="fields.allowPaypal"
                      flat
                      true-value="1"
                      false-value="0"
                      :disabled="!hasPayPalCredentials"
                      :label="$t('allowPayPal')"
                    ></v-switch>
                  </template>
                </v-col>

                <v-col
                  v-if="fields.allowPaypal === '1' && fields.noPayment !== '1'"
                  cols="12"
                >
                  <v-select
                    v-model="fields.paypalEnvironment"
                    :items="paypalEnvironments"
                    item-text="name"
                    item-value="value"
                    :label="$t('environment')"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card>
            <v-card-title>{{ $t("ageVerification") }}</v-card-title>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-switch
                    inset
                    v-model="fields.ageVerification"
                    flat
                    true-value="1"
                    false-value="0"
                    :label="$t('ageVerification')"
                  ></v-switch>
                </v-col>

                <template v-if="fields.ageVerification === '1'">
                  <v-col cols="12">
                    <v-text-field
                      :label="$t('minimumAge')"
                      type="number"
                      v-model="fields.minimumAge"
                    ></v-text-field>
                  </v-col>

                  <!-- <v-col cols="12">
                    <v-switch
                      inset
                      v-model="fields.excludeUnderage"
                      flat
                      true-value="1"
                      false-value="0"
                      :label="$t('excludeUnderage')"
                    ></v-switch>
                  </v-col> -->

                  <v-col v-if="fields.excludeUnderage !== '1'" cols="12">
                    <h3>{{ $t("underageText") }}</h3>

                    <v-md-editor
                      v-model="fields.underageText"
                      height="400px"
                      left-toolbar="h bold italic | ul ol | emoji"
                      right-toolbar="preview fullscreen"
                      :disabled-menus="['h/h1']"
                      mode="edit"
                    ></v-md-editor>
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card>
            <v-card-title>{{ $t("Meta") }}</v-card-title>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-switch
                    inset
                    v-model="fields.isActive"
                    flat
                    true-value="1"
                    false-value="0"
                    :label="$t('active')"
                  ></v-switch>

                  <v-switch
                    inset
                    v-model="fields.isHighlight"
                    flat
                    true-value="1"
                    false-value="0"
                    :label="$t('bestOffer')"
                  ></v-switch>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    :label="$t('recommendationUrl')"
                    v-model="fields.recommendationUrl"
                    :hint="$t('recommendationUrlHint')"
                    persistent-hint
                  ></v-text-field>
                </v-col>

                <v-col v-if="itemData" cols="12" class="mt-4">
                  <v-text-field
                    label="UID"
                    disabled
                    v-model="this.itemData.uuid"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div class="d-flex align-end flex-column">
            <v-row>
              <v-btn color="grey" text @click="$emit('closeDialog')">{{
                $t("cancel")
              }}</v-btn>
              <v-btn
                v-if="isFormValid"
                color="green"
                dark
                @click="safeData"
                :loading="fetchinData"
                >{{ $t("safe") }}</v-btn
              >
              <v-btn v-else disabled>{{ $t("safe") }}</v-btn>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-form>

    <v-row class="mt-6" v-if="!this.new">
      <v-col
        cols="12"
        v-if="
          this.adminId === '1' ||
          this.adminId === '836' ||
          this.adminId === '846' ||
          this.adminId === '868' ||
          this.adminId === '2169' ||
          this.adminId === '7' ||
          this.adminId === '869' ||
          this.adminId === '2107' ||
          this.adminId === '847' ||
          this.adminId === '861' ||
          this.adminId === '845'
        "
      >
        <v-card>
          <v-card-title>Einbettungs-Code</v-card-title>
          <v-card-text>
            <code
              class="block whitespace-pre overflow-x-scroll grey lighten-3"
              v-text="snippet()"
            ></code>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
export default {
  name: "ContractDetail",

  components: {},

  props: ["id", "itemData", "new"],

  data() {
    return {
      fields: {
        title: null,
        vatRate: null,
        description: null,
        isActive: null,
        billingPeriod: null,
        features: null,
        finePrint: null,
        isHighlight: null,
        runtimeCount: null,
        runtimePeriod: null,
        feePrice: null,
        feePriceGross: null,
        feeTitle: null,
        feeDescription: null,
        priceGross: null,
        image: null,
        promoPriceGross: null,
        promoTitle: null,
        promoRuntimeCount: null,
        promoRuntimePeriod: null,
        promoBillingPeriod: null,
        apiProvidersUsed: null,
        apiProviders_id: null,
        apiProvidersContractId: null,
        allowSepa: null,
        noPayment: null,
        noPaymentText: "",
        allowPaypal: null,
        paypalEnvironment: null,
        recommendationUrl: null,
        ageVerification: null,
        minimumAge: null,
        underageText: "",
        excludeUnderage: null,
        currencyCode: null,
        startAfterPromo: null,
      },
      hasPayPalCredentials: false,
      image: null,
      randomValue: (Math.random() + 1).toString(36).substring(7),
      fetchinData: false, // should be true when fetching data
      fetchingProvider: false,
      companyID: store.getters["auth/current_sid"],
      adminId: store.getters["auth/adminid"],
      componentKey: 0,
      isFormValid: false,

      currencyCodes: ["EUR", "CHF", "USD", "GBP"],

      billingPeriods: [
        {
          name: this.$t("daily"),
          value: "daily",
        },
        {
          name: this.$t("weekly"),
          value: "weekly",
        },
        {
          name: this.$t("monthly"),
          value: "monthly",
        },
        {
          name: this.$t("quarterly"),
          value: "quarterly",
        },
        {
          name: this.$t("halfYearly"),
          value: "half-yearly",
        },
        {
          name: this.$t("yearly"),
          value: "yearly",
        },
        {
          name: this.$t("once"),
          value: "once",
        },
      ],

      runtimePeriods: [
        {
          name: this.$t("day"),
          value: "daily",
        },
        {
          name: this.$t("week"),
          value: "weekly",
        },
        {
          name: this.$t("month"),
          value: "monthly",
        },
        {
          name: this.$t("quarter"),
          value: "quarterly",
        },
        {
          name: this.$t("halfYear"),
          value: "half-yearly",
        },
      ],

      paypalEnvironments: [
        {
          name: this.$t("sandboxEnvironment"),
          value: "test",
        },
        {
          name: this.$t("liveEnvironment"),
          value: "live",
        },
      ],

      rules: {
        required: (value) => !!value || this.$t("required"),
      },
    };
  },

  mounted: function () {
    if (!this.new) {
      for (const [key] of Object.entries(this.fields)) {
        if (this.itemData[key] && this.itemData[key] !== "null") {
          this.fields[key] = this.itemData[key];
        }
      }
    }
    this.checkForPayPalCredentials();
  },

  methods: {
    snippet() {
      if (this.itemData) {
        const snippet = `<div id="mfpay-contracts" campaign="[CAMPAIGN UUID]" contract="${this.itemData.uuid}" footer="false"></div><script>var d = document,g = d.createElement("script"),s = d.getElementsByTagName("script")[0];g.type = "text/javascript";g.async = true;g.defer = true;g.src = "${process.env.VUE_APP_CONTRACTS_URL}assets/embed_external.js";g.onload = function () {var instance = new init();instance.init();};s.parentNode.insertBefore(g, s);<\/script>`; // eslint-disable-line
        return snippet;
      }
    },

    fileReader(input) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        (reader.onload = (e) => {
          console.log("resolved");
          let file = e.target.result;
          resolve(file);
        }),
          (reader.onerror = () => {
            console.log("resolved false");
            resolve(false);
          });
        reader.readAsDataURL(input);
      });
    },

    async checkForPayPalCredentials() {
      // check if paypal credentials are set
      this.fetchinData = true;
      let response = await this.getRequest(
        `companies/${this.companyID}/paypal`
      );

      if (response.status === 200) {
        // Hier wird vorerst nur gecheckt, ob Daten da sind.
        // Ob diese valide sind, wird erst in Zukunft überprüft. -> https://github.com/studios-in-motion-team/mitfit-api/issues/275
        if (
          response.data.data.liveClientId !== "" &&
          response.data.data.liveSecret !== ""
        ) {
          this.hasPayPalCredentials = true;
        }
      }
      this.fetchinData = false;
    },

    async safeData() {
      let file = "";

      if (this.image !== "" && this.image != null) {
        file = await this.fileReader(this.image);
      }

      if (this.new) {
        // create new contract

        var formData = new FormData();
        formData.append("admin_id", this.adminId);
        formData.append("companies_id", this.companyID);
        if (this.image !== "" && this.image != null) {
          formData.append("file", file);
        }

        for (const [key, value] of Object.entries(this.fields)) {
          if (value) {
            formData.append(key, value);
          }
        }

        this.fetchinData = true;
        let response = await this.postRequest("pay/contracts", null, formData); // await data from mixin
        this.fetchinData = false;

        if (response.status === 200) {
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
          this.$refs.form.reset();
          this.$emit("newItemAdded");
        } else {
          /* Mail already exists */
          this.mailErrorDialog = true;
          this.fetchinData = false;
        }
      } else {
        // update existing contract

        let data = {};

        this.fields["admin_id"] = this.adminId;
        this.fields["companies_id"] = this.companyID;
        if (this.image !== "" && this.image != null) {
          this.fields["file"] = file;
        }

        for (const [key, value] of Object.entries(this.fields)) {
          if (value) {
            data[key] = value;
          } else {
            // Must be null, otherwise there will be no update (clear something eg.)
            data[key] = null;
          }
        }

        var dataArr = new URLSearchParams();
        dataArr.append("data", JSON.stringify(data)); // das komplette JSON übergeben

        this.fetchinData = true;
        let response = null;
        response = await this.putRequest(
          "pay/contracts/" + this.id,
          data,
          "json"
        ); // await data from mixin
        this.fetchinData = false;

        /* mutate itemData */
        for (const [key, value] of Object.entries(this.fields)) {
          if (value) {
            this.itemData[key] = response.data.data[key];
          } else {
            // Must be null, otherwise there will be no update (clear something eg.)
            this.itemData[key] = null;
          }
        }

        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
        this.randomValue = (Math.random() + 1).toString(36).substring(7);
        //this.$emit("closeDialog");
      }
    },
  },

  /* all requests by mixin */
  mixins: [manageApi],
};
</script>
