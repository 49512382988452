<template>
  <div>
    <v-card :loading="fetchingData">
      <v-toolbar dark color="dark">
        <v-toolbar-title>{{ $t("PaymentStatus") }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <Doughnut
          :chartData="chartData"
          :chartLabels="chartLabels"
          :chartColors="chartColors"
          v-if="!fetchingData && dataProcessed"
        /><!-- wenn daten für chart aufbereitet wurden -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import Doughnut from "@/components/charts/Doughnut";
export default {
  name: "PaymentDistributionCard",

  components: {
    Doughnut,
  },

  data() {
    return {
      fetchingData: false,
      dataProcessed: false,
      chartData: [],
      chartLabels: [this.$t("Complete"), this.$t("invoice")],
      chartColors: ["#7CAF2A", "#C8E6C9"],
      companyID: store.getters["auth/current_sid"],
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest(
        `companies/${this.companyID}/memberLeads/leadsStatsByPaymentStatus`
      );
      this.fetchingData = false;
      if (response.status === 200) {
        this.data = response.data.data;
        this.processDataForChart();
      }
    },

    processDataForChart() {
      /* Aufbereiten der Daten für die Verwendung im Chart */
      //const dataArr = Object.values(this.data);
      Object.keys(this.data).forEach((key) => {
        this.chartData.push(this.data[key]);
      });
      this.backgroundColor = "rgba(124,174,43,.5)";
      this.dataProcessed = true;
    },
  },
  mixins: [manageApi],
};
</script>
