<template>
  <v-container v-if="!fetchinData">
    <template v-if="hasAddon()">
      <PageHeader
        :pageTitle="pageTitle"
        :pageDescription="$t('pageDescriptions.payContracts')"
      />

      <template>
        <v-tabs v-model="tab" align-with-title dark background-color="primary">
          <v-tabs-slider color="lime accent-2"></v-tabs-slider>
          <v-tab v-for="(item, index) in items" :key="index">
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </template>

      <v-tabs-items v-model="tab" class="grey lighten-4">
        <v-tab-item v-for="(item, index) in items" :key="index">
          <v-card flat class="grey lighten-4">
            <component v-bind:is="item.component"></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <template v-else>
      <PageHeader
        :pageTitle="pageTitle"
        :pageDescription="$t('pageDescriptions.payContracts')"
      />
      <p>
        Das Modul ist nicht verfügbar.<br />Bei Fragen oder Interesse
        kontaktiere bitte deinen FACEFORCE Berater.
      </p>

      <MarketingFallback
        :buyAction="true"
        title="mitfitMEMBER"
        content="mitfitMEMBER ist ein digitales System, welches über alle denkbaren digitalen Kanäle neue Mitgliedschaften generiert. <strong>Mitgliedschaften? Ja, Mitgliedschaften mit Vertrag und gültigem SEPA-Mandat.</strong>"
        url="https://www.face-force.de/online-marketing/mitfitmember/"
      />
    </template>
  </v-container>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import PageHeader from "@/components/ui/PageHeader";
import ContractsList from "@/components/addons/member/ContractsList";
import MemberLeads from "@/components/addons/member/MemberLeads";
import MarketingFallback from "@/components/addons//MarketingFallback";

export default {
  name: "AddonMember",

  components: {
    PageHeader,
    ContractsList,
    MemberLeads,
    MarketingFallback,
  },

  data() {
    return {
      pageTitle: "mitfitMEMBER",
      pageDescription:
        "Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta.",
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      addons: [], // wrapper for fetched data
      tab: null,
      items: [
        {
          name: this.$t("sales"),
          component: "MemberLeads",
        },
        {
          name: this.$t("contracts"),
          component: "ContractsList",
        },
      ],

      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },

  props: [],

  created: function () {
    // allow to change company?
    this.$store.commit("companyChangeOption/SET_ALLOWED", true);

    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        //this.getData();
        this.getAddons();
      }
    );

    //this.getData();
    this.getAddons();
  },

  methods: {
    clientBlockHack() {
      /* Auf Wunsch wird die Ausgabe für bestimmt Kunden blockiert.
      Das Feature gilt so lange, bis das Manage MEMBER-Addon fertiggestellt wurde. */
      if (
        this.$store.getters["auth/current_sid"] == 480 ||
        this.$store.getters["auth/current_sid"] == 384
      ) {
        return false;
      } else {
        return true;
      }
    },

    hasAddon() {
      // check if addon id 6 is available in this.addons
      let addon = this.addons.find((addon) => addon.id == "6");
      if (addon) {
        return true;
      } else {
        return false;
      }
    },

    async getAddons() {
      this.fetchinData = true;
      let response = await this.getRequest(
        `companies/${this.companyID}/addons`
      );
      this.addons = response.data.data;
      this.fetchinData = false;
    },

    async getData() {
      // ! vorerst deaktiviert, da das dem Anschein nach nicht mehr benötigt wird
      /* fetch data from api */
      this.fetchinData = true;
      let response = await this.getRequest("company/" + this.companyID); // await data from mixin
      this.fetchinData = false;
      this.data = response.data.status;
    },
  },

  computed: {
    //
  },

  /* all requests by mixin */
  mixins: [manageApi],
};
</script>
